import { Link } from 'gatsby';
import React from 'react';
import { navigate } from '@reach/router';

import MenuSharpIcon from '@material-ui/icons/MenuSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

import { cn } from '../lib/helpers';

import * as styles from './header.module.css';
import HekoTextIcon from './icon/HekoTextIcon';
import NavigationRow from './navigation-row';

const FullScreenNavLink = ({ beforeNavigate = () => {}, to, children, ...rest }) => {
  const performNavigate = () => {
    beforeNavigate();
    navigate(to);
  };
  return (
    <a onClick={performNavigate} {...rest}>
      {children}
    </a>
  );
};

const Header = ({ onHideNav, onShowNav, showNav, siteTitle, navEnabled = false }) => (
  <>
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div></div>
        <div className={styles.branding}>
          <Link to="/">
            <span hidden>{siteTitle}</span>
            <HekoTextIcon width="100%" fill="black" />
          </Link>
        </div>

        <NavigationRow className={styles.desktopNav} />
      </div>
      {navEnabled && (
        <button className={styles.toggleNavButton} onClick={onShowNav}>
          <MenuSharpIcon />
        </button>
      )}
    </div>

    {navEnabled && (
      <nav className={cn(styles.nav, showNav && styles.showNav, 'text-lg')}>
        <button className={styles.closeNavButton} onClick={onHideNav}>
          <CloseSharpIcon />
        </button>
        <div className={styles.listWrapper}>
          <NavigationRow vertical />
        </div>
      </nav>
    )}
  </>
);

export default Header;
