import React from 'react';

const HekoTextIcon = ({ fill = 'white', ...rest }) => (
  <svg
    width="640"
    height="86"
    viewBox="0 0 640 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M53.6 47.8H46.6V54.1H40.4V38.4H46.6V43.9H53.6V38.4H59.8V54.1H53.6V47.8Z" fill={fill} />
    <path d="M77.1 38.4H93.8V42.2H83.3V44.3H93V48.1H83.3V50.2H93.8V54H77.1V38.4Z" fill={fill} />
    <path d="M110.2 38.4H116.4V54.1H110.2V38.4Z" fill={fill} />
    <path
      d="M142.1 48L140.1 49.8V54H133.9V38.4H140.1V43.9L145.9 38.4H154.1L147.1 44.5L154.7 54.1H146.4L142.1 48Z"
      fill={fill}
    />
    <path
      d="M178 48L176 49.8V54H169.8V38.4H176V43.9L181.8 38.4H190L183 44.5L190.6 54.1H182.3L178 48Z"
      fill={fill}
    />
    <path d="M205.6 38.4H211.8V54.1H205.6V38.4Z" fill={fill} />
    <path
      d="M229.2 38.4H236.3L242.6 47.1H242.7V38.4H248.6V54.1H241.8L235.2 45.3V54.1H229.3V38.4H229.2Z"
      fill={fill}
    />
    <path
      d="M265.9 38.4H282.6V42.2H272.1V44.3H281.8V48.1H272.1V50.2H282.6V54H265.9V38.4Z"
      fill={fill}
    />
    <path
      d="M299 38.4H306.1L312.4 47.1H312.5V38.4H318.4V54.1H311.6L305 45.3V54.1H299V38.4Z"
      fill={fill}
    />
    <path
      d="M334.2 45.7H339.3V40.6H340.2V45.7H345.3V46.6H340.2V51.7H339.3V46.6H334.2V45.7Z"
      fill={fill}
    />
    <path
      d="M369.3 48L367.3 49.8V54H361.1V38.4H367.3V43.9L373.1 38.4H381.3L374.3 44.5L381.9 54.1H373.6L369.3 48Z"
      fill={fill}
    />
    <path
      d="M406.8 37.8C410.6 37.8 417.5 38.1 417.5 46.2C417.5 54.3 410.5 54.6 406.8 54.6C403.1 54.6 396.1 54.3 396.1 46.2C396.1 38.1 403.1 37.8 406.8 37.8ZM406.8 50.7C409.3 50.7 410.9 49.4 410.9 46.3C410.9 43.1 409.3 41.9 406.8 41.9C404.3 41.9 402.7 43.2 402.7 46.3C402.7 49.4 404.3 50.7 406.8 50.7Z"
      fill={fill}
    />
    <path
      d="M434.1 38.4H442.8L445.4 47.5L448 38.4H456.7V54.1H451.1V42.9L447.8 54.1H443L439.7 42.9V54.1H434.1V38.4Z"
      fill={fill}
    />
    <path
      d="M483.9 37.8C487.7 37.8 494.6 38.1 494.6 46.2C494.6 54.3 487.6 54.6 483.9 54.6C480.1 54.6 473.2 54.3 473.2 46.2C473.2 38.1 480.2 37.8 483.9 37.8ZM483.9 50.7C486.4 50.7 488 49.4 488 46.3C488 43.1 486.4 41.9 483.9 41.9C481.4 41.9 479.8 43.2 479.8 46.3C479.8 49.4 481.4 50.7 483.9 50.7Z"
      fill={fill}
    />
    <path
      d="M511.2 38.4H518.3L524.6 47.1H524.7V38.4H530.6V54.1H523.8L517.2 45.3V54.1H511.3V38.4H511.2Z"
      fill={fill}
    />
    <path
      d="M547.9 38.4H564.6V42.2H554.1V44.3H563.8V48.1H554.1V50.2H564.6V54H547.9V38.4Z"
      fill={fill}
    />
    <path
      d="M580.9 38.4H588L594.3 47.1H594.4V38.4H600.3V54.1H593.5L586.9 45.3V54.1H581V38.4H580.9Z"
      fill={fill}
    />
  </svg>
);

export default HekoTextIcon;
