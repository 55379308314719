import React from 'react';
import { Link } from 'gatsby';
import { cn } from '../lib/helpers';

import * as styles from './navigation.module.css';

const NavigationRow = ({ className, vertical = false }) => {
  return (
    <ul className={cn(styles.navigationRow, vertical ? styles.vertical : styles.horizontal)}>
      <li>
        <Link to="/" className={styles.link} title="Etusivulle" activeClassName={styles.active}>
          Etusivu
        </Link>
      </li>
      <li>
        <Link
          to="/contact"
          className={styles.link}
          title="Yhteystiedot"
          activeClassName={styles.active}
        >
          Yhteystiedot
        </Link>
      </li>
    </ul>
  );
};

export default NavigationRow;
