import React from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from 'react-helmet';

import * as styles from './layout.module.css';
import '../styles/layout.css';

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, logoVersion }) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <Helmet>
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    </Helmet>
    <div className={styles.content}>{children}</div>
    <Footer logoVersion={logoVersion} />
  </>
);

export default Layout;
