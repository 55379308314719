import React from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import { cn } from '../lib/helpers';
import * as styles from './footer.module.css';
import Container from './container';
import svgLogoA from './icon/HEIKOM_Logo_Green.svg';
import svgLogoB from './icon/HEIKOM_Logo_Orange.svg';

const logos = {
  A: svgLogoA,
  B: svgLogoB
};

const spacing = 8;

const Footer = ({ logoVersion = 'A' }) => {
  return (
    <Container>
      <footer className={styles.footer}>
        <Grid container spacing={spacing}>
          <Grid item xs={12} sm={6} className={styles.leftArea}>
            <img alt="Heikkinen-Komonen logo" src={logos[logoVersion]} className={styles.logo} />
          </Grid>
          <Grid item xs={12} sm={6} container spacing={2}>
            <Grid item xs={12} className={styles.flexRow}>
              <p className={styles.bold}>
                <b>
                  Arkkitehtuuritoimisto
                  <br />
                  Heikkinen-Komonen Oy
                </b>
              </p>
              <p className="margin-0">
                <a href="tel:+358103210400" title="Soita +358 103 210 400">
                  +358 103 210 400
                </a>
                <br />
                <a href="mailto:ark@heikkinen-komonen.fi">ark@heikkinen-komonen.fi</a>
              </p>
            </Grid>

            <Grid item xs={12}>
              <p className={styles.siteInfo}>
                &copy; Heikkinen-Komonen Architects {new Date().getFullYear()}. All rights reserved.{' '}
                <Link to="/tietosuojaseloste" title="Tietosuojaseloste">
                  Tietosuojaseloste
                </Link>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </Container>
  );
};

export default Footer;
